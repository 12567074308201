import React, {useState} from 'react';
import './AppLateral.css';
import Lottie from 'react-lottie-player'
import lottieJson from './img/74225-super-tech-support-by-praveen-singh.json';
import lottieJsonServ from './img/38834-service-animation.json';
import lottieJsonDev from './img/91194-development.json';
import lottieJsonRt from './img/9704-ecommerce.json';

import lottieJsonMap from './img/47956-area-map.json';
import { Button, Modal, Container, Row} from 'react-bootstrap';

function AppLateral() {
  //Servicios
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Desarrollo
  const [showDev, setShowDev] = useState(false);
  const handleCloseDev = () => setShowDev(false);
  const handleShowDev = () => setShowDev(true);

  //Tienda
  const [showRt, setShowRt] = useState(false);
  const handleCloseRt = () => setShowRt(false);
  const handleShowRt = () => setShowRt(true);

  //Contacto
  const [showCont, setShowCont] = useState(false);
  const handleCloseCont = () => setShowCont(false);
  const handleShowCont = () => setShowCont(true);

  return (
    <div className="App">
      <header className="App-header">
      <ul>
        <li><Button variant="link" className="App-link" onClick={handleShow}>Servicios</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowDev}>Desarrollo</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowRt}>Tienda</Button></li>
        <li><Button variant="link" className="App-link" onClick={handleShowCont}>Contacto</Button></li>
        <Container>
            <Row className="justify-content-center">
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: 250, height: 250 }}
            />
            </Row>
          </Container>
      </ul>
      </header>
      {/* Servicios */}
      <Modal show={show} onHide={handleClose}>
       <Modal.Header closeButton>
           <Modal.Title>Iramundi - Servicios</Modal.Title>
         </Modal.Header>
         <Modal.Body>
                  Brindamos una amplia gama de servicios:
                  <p></p>
                    👉 Soporte, Instalacion y Mantenimiento de Sistemas Informaticos<br></br>
                    👉 Implementamos las Nuevas Tecnologías Disponibles en el Mercado<br></br>
                    👉 Trabajo Remoto y Interconexion de Sucursales<br></br>
                    👉 Monitorización de Dispositivos, Sistemas y Servidores<br></br>
                    👉 Virtualizacion de Procesos, Servidores y Aplicaciones<br></br>
                    👉 Respaldos y Restauracion de Informacion<br></br>
                    👉 Regularizacion y Licenciamiento de Software<br></br>
                    👉 Diseño e Implementacion de Soluciones en Nube Publica y Privada<br></br>
                    👉 Gestion y Ampliacion de Garantias de Hardware y Software<br></br>
                  <p></p>
            <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonServ}
              play
              style={{ width: 250, height: 250 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* Desarrollo */}
      <Modal show={showDev} onHide={handleCloseDev}>
       <Modal.Header closeButton>
           <Modal.Title>Iramundi - Desarrollo</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          Desarrollamos aplicaciones a Medida:
                  <p></p>
                    👉 Desarrollo de Sitios Web Institucionales<br></br>
                    👉 Desarrollo de Aplicaciones Web y Mobiles<br></br>
                    👉 Desarrollo de API para Interconexion de Sistemas<br></br>
                    👉 Desarrollo de Sitios para E-Commerce<br></br>
                    👉 Desarrollo de Intranets Coorporativas<br></br>
                    👉 Alojamiento de Soluciones Web y Aplicaciones<br></br>
                  <p></p>
            <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonDev}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseDev}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* Tienda */}
      <Modal show={showRt} onHide={handleCloseRt}>
       <Modal.Header closeButton>
           <Modal.Title>Iramundi - Tienda</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          Tendremos disponible nuestra tienda On-Line a la brevedad
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={true} 
              animationData={lottieJsonRt}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseRt}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      {/* CONTACTO */}
      <Modal show={showCont} onHide={handleCloseCont}>
       <Modal.Header closeButton>
           <Modal.Title>Iramundi - Contacto</Modal.Title>
         </Modal.Header>
         <Modal.Body>
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJsonMap}
              play
              style={{ width: 350, height: 350 }}
            />
            </Row>
          </Container>
            <p></p>
            🏢 Direccion: Ituzaingo 1512 - Montevideo<br></br>
            📧 Email: info@iramundi.com<br></br>
            ☎️ Telefono: (+598)99185967<br></br>
            <p></p>
          </Modal.Body>
         <Modal.Footer>
           <Button variant="link" onClick={handleCloseCont}>Cerrar</Button>
        </Modal.Footer>
      </Modal>      
    </div>
  );
}

export default AppLateral;