import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './img/75663-call-center-support-lottie-animation.json';
import lottieJson2 from './img/98260-success.json'
import { Button, Modal, Container, Row } from 'react-bootstrap';
import './App.css';
import 'animate.css';

function App() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  return (
    <div className="App">
      <header className="App-header align-top">
      <h1 className="animate__animated animate__zoomIn">Iramundi - Soluciones IT</h1>
      <Lottie
          loop={false}
          animationData={lottieJson}
          play
          style={{ width: 350, height: 350 }}
        />
        <b></b>
        <p></p>
        <b></b>
        <h2>Sus problemas Informaticos tienen Solucion</h2>
        <b></b>
        <p></p>
        <b></b>
        <Button variant="link" className="App-link animate__animated animate__flip" onClick={handleShow}>Mas info...</Button>
      </header>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sus problemas Informaticos tienen Solucion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Brindamos servicios informáticos a PYMES, las ayudamos a garantizar el correcto funcionamiento de sus sistemas, permitiéndole a sus empresarios y directivos centrarse en su negocio.
          <p></p>
          Desde hace más de 10 años prestamos servicios a reconocidas empresas de plaza ofreciendo Soporte e Instalaciones, Consultoría y Auditoría Informática, Redes Privadas, Monitoreo Preventivo, Accesos Remotos, Tercerización de servicios, Soluciones de Teletrabajo, Virtualización, Respaldos y Restauracion de Informacion, Servicios de Nubes Publicas, Licenciamiento de Software y Control de Activos Informaticos.
          Desarrollamos soluciones Web y de Nube para que su empresa este siempre conectada con Clientes y Proveedores.
          <Container>
            <Row className="justify-content-center">
            <Lottie
              loop={false} 
              animationData={lottieJson2}
              play
              style={{ width: 250, height: 250 }}
            />
            </Row>
          </Container>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}

export default App;
