import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootswatch/dist/vapor/bootstrap.min.css";
import './index.css';
import App from './App';
import AppLateral from './AppLateral';
import { Container, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactGA4 from "react-ga4";

ReactGA4.initialize('G-BBP4VG8BS8');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
          <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Iramundi - Soluciones IT</title>
                <meta name="Iramundi Servicios Informaticos" content="Soluciones IT - Uruguay" />
            </Helmet>
            <Container>
              <Row>
                <Col sm={4}>
                  <AppLateral />
                </Col>
                <Col sm={8}>
                  <App />
                </Col>
              </Row>
          </Container>
        </div>
  </React.StrictMode>
);

